@tailwind base;
@tailwind components;
@tailwind utilities;




body {
  margin: 0;
  font-family:'Noto Sans Thai', sans-serif ,  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.bg-img {
  width: 100%;
  background-image: url(../public/images/main.png);
  background-size: cover;
  background-position: 50%
}
@media(min-width: 640px) {
  .bg-img {
      background-position:top
  }
}
/* @media(min-width: 2000px) {
  .bg-img {
      background-position:top;
      background-size: contain;
      background-repeat: no-repeat
  }
} */

@media(min-width: 768px) {
  .map {
      width:40rem
  }

  .text-title {
      bottom: 10%;
      left: 25%
  }
}

@media(min-width: 1240px) {
  .text-title {
      bottom:10%;
      left: 40%
  }

  .qr {
      width: 500px!important
  }
}

#timer,h1 {
 font-family: Lato,sans-serif; 
  text-align: center;
  text-transform: uppercase;
 letter-spacing: 1px;
  color: #f6f4f3
}

#timer {
  font-size: .7em;
  margin-top: 25%
}

.days,.hours,.minutes,.seconds {
  display: inline-block;
  padding: 20px;
  width: 25%;
  border-radius: 5px;
  text-align: center;
  color: rgb(185, 177, 166);
}

.days,.hours {
  background: #f6f4f3
}

.hours {
  color: #183059
}

.minutes,.seconds {
  background: #f6f4f3
}

.numbers {
  /* font-family: Montserrat,sans-serif; */
  /* font-family: sans-serif; */
  

 color: #104b63 ;

 font-weight: 900;
  font-size: 2.5rem
}

